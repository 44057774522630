import UserChatSkeleton from "components/user-chat/UserChatSkeleton";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { IoCheckmark, IoCheckmarkDone } from "react-icons/io5";
function UserChatBody({
  sidePanel,
  chatMessages,
  showChatProgress,
  loadMoreMessages,
}: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const isSingleEmoji = (message: any) => {
    const emojiRegex = new RegExp("^[\u{1F000}-\u{1F9FF}]$", "gu");
    return emojiRegex.test(message);
  };

  const isGifUrl = (url: string) => {
    const gifUrlPattern = /^https:\/\/giphy\.com\/embed\/[a-zA-Z0-9]+$/;
    return gifUrlPattern.test(url);
  };

  return (
    <>
      <div
        className={`px-5 py-3 bg-[var(--Color1E1E1E)] pb-5 ${
          sidePanel ? `min-h-full ` : `h-full`
        }`}
      >
        {chatMessages ? (
          <>
            {chatMessages?.remaining_messages_count > 0 && (
              <div
                className="text-center text-white duration-200 ease-in-out cursor-pointer hover:text-gray-300"
                onClick={loadMoreMessages}
              >
                Load More
              </div>
            )}

            {chatMessages?.messages?.map((chat: any, index: any) => {
              // Parse the timestamp
              const chatTime = new Date(chat?.created_at);
              // Extract hours and minutes
              const hours = chatTime.getHours();
              const minutes = chatTime.getMinutes();
              if (chat?.user !== null) {
                return (
                  <div key={index} className="mb-4">
                    <div className="flex items-start">
                      {/* Display the extracted time */}
                      <p
                        className={`text-gray-500 text-[9px] flex justify-end items-end mt-2 font-light  mb-[2px] ${
                          chat?.user_id === loggedInUser?.id
                            ? `ml-auto`
                            : `mr-auto`
                        }`}
                      >
                        {hours}:{minutes < 10 ? "0" + minutes : minutes}
                      </p>
                    </div>
                    <div className="relative flex">
                      <div
                        className={`px-3 py-2 mt-1 text-[15px] font-light chat-bubble ${
                          chat?.user_id === loggedInUser?.id
                            ? "bg-[var(--chat-bubble-blue)] text-white rounded-bl-xl rounded-br-xl rounded-tl-xl ml-auto"
                            : "bg-white text-gray-800 rounded-bl-xl rounded-br-xl rounded-tr-xl mr-auto"
                        }`}
                      >
                        <div
                          className={`flex justify-between items-end  ${
                            isSingleEmoji(chat?.message)
                              ? "text-[40px]"
                              : "text-[15px]"
                          }`}
                        >
                          {isGifUrl(chat?.message || chat?.content) ? (
                            <div className="relative">
                              <iframe
                                src={chat?.message || chat?.content}
                                width="100%"
                                height="300"
                                frameBorder="0"
                                className="giphy-embed"
                                allowFullScreen
                                title="GIF"
                              ></iframe>
                            </div>
                          ) : (
                            // <></>
                            chat?.message ||
                            chat?.content
                              .split("\n")
                              .map((line: any, index: any) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))
                          )}
                          <div className="ml-1">
                            {chat?.user_id == loggedInUser?.id ? (
                              !chat.is_read ? (
                                <IoCheckmark className="text-blue-500" />
                              ) : (
                                <IoCheckmarkDone className="text-blue-500" />
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </>
        ) : (
          <>
            <UserChatSkeleton />
          </>
        )}
        {showChatProgress && (
          <div className="flex">
            <img
              src={
                "https://www.coastpaint.com/cdn/shop/products/444A50_1024x.png?v=1609869405"
              }
              alt="Avatar"
              className="w-8 h-8 mr-2 bg-black rounded-full"
            />
            <div className="typing">
              <div className="typing__dot"></div>
              <div className="typing__dot"></div>
              <div className="typing__dot"></div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UserChatBody;
