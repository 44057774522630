import LazyLoadImg from "components/LazyLoadImg";
import { RouteName } from "constant/routeNames";
import { useState } from "react";
import { Link } from "react-router-dom";
import AddPlaylistToFav from "../shared/AddPlaylistToFav";
import { Tooltip as ReactTooltip } from "react-tooltip";

function PlaylistCard({
  playlist,
  welcomePage,
  libraryPage,
  userPage,
  discoverPlayist,
  playlistPage,
  searchPage,
}: any) {
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);
  // const [favorite, setFavorite] = useState<any>();
  // const [isLoading, setIsLoding] = useState<any>(false);

  const popupTrigger = () => {
    setActive((val: boolean) => !val);
  };
  return (
    <Link
      to={
        welcomePage === true
          ? RouteName.Home.replace(
              "/",
              RouteName.MusicPlaylistPage.replace(":id", playlist.id)
            )
          : libraryPage === true
          ? RouteName.Playlists.replace(
              "/playlist",
              RouteName.MusicPlaylistPage.replace(":id", playlist.id)
            )
          : userPage === true
          ? RouteName.UserProfile.replace(
              "/profile/*",
              RouteName.MusicPlaylistPage.replace(":id", playlist.id)
            )
          : discoverPlayist === true
          ? RouteName.SeeAllDiscoverPlaylistsRoute.replace(
              "all-playlists",
              RouteName.MusicPlaylist.replace(":id", playlist.id)
            )
          : playlistPage === true
          ? RouteName.Music.replace(
              "*",
              RouteName.MusicPlaylist.replace(":id", playlist.id)
            )
          : searchPage === true
          ? RouteName.MusicPlaylistPage.replace(":id", playlist.id)
          : RouteName.MusicPlaylist.replace(":id", playlist.id)
      }
    >
      <div
        className="mx-auto inline-block w-[176px] h-[270px]  px-2 py-2 rounded-md shadow-lg cursor-pointer duration-300 ease-in-out hover:text-slate-300 text-white hover:bg-slate-800 hover:shadow-xl "
        // onClick={handleMusicPlayer}
      >
        <div className="relative w-[100%] h-[176px] rounded-lg  bg-img white-text max-sm:w-[98%] max-sm:h-[180px]">
          <LazyLoadImg
            imgSrc={playlist?.artwork_url}
            imgAlt={playlist?.title}
            imgHeight={"100%"}
            imgWidth={"100%"}
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              borderRadius: "5px",
            }}
          />

          {/* card inside content */}
          <div className="absolute bottom-0 flex justify-end w-full p-2">
            <div
              className="items-center p-1 rounded-full add-button"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <AddPlaylistToFav
                playlist={playlist}
                playlistCard={true}
                hover={hover}
              />
            </div>
          </div>
        </div>
        <div className="relative top-1">
          <div className="my-[4px]">
            <p
              className="text-[15px] font-[600]"
              data-tooltip-id={`playlist-${playlist?.id}`}
            >
              {playlist?.title?.length > 20
                ? `${playlist?.title.slice(0, 20)}...`
                : playlist?.title}
            </p>

            {playlist?.title?.length > 20 && (
              <div className="text-sm">
                <ReactTooltip
                  id={`playlist-${playlist?.id}`}
                  place="top"
                  content={playlist?.title}
                />
              </div>
            )}

            <p className="text-[12px] font-[400] text-[#648194] mt-[5px]">
              {playlist?.song_count} Songs
            </p>
          </div>
        </div>
        {active ? (
          <>
            <div
              className="absolute w-[400px] h-[400px] bg-red-800 top-0"
              onClick={popupTrigger}
            ></div>
          </>
        ) : (
          <></>
        )}
      </div>
    </Link>
  );
}

export default PlaylistCard;
