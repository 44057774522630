export const ChatStatusButton = ({
  handleApproveChat,
  className,
  text,
  status,
}: any) => {
  return (
    <p
      className={`${className} px-4 py-2 cursor-pointer border-[1px] border-opacity-0 hover:border-opacity-100 rounded-full transition-all`}
      onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
        e.stopPropagation();
        handleApproveChat(status);
      }}
    >
      {text}
    </p>
  );
};
