import { Input } from "antd";
import { ChatContext } from "context/ChatContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { BsArchive, BsChat, BsChatQuote, BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import AllChatSelectorIcon from "./AllChatSelectorIcon";
import ChatList from "./ChatList";
import { store } from "redux/store";
import { useAppSelector } from "app/hooks";

function AllChats({ setChatIsOpen, setSelectedChat, sidePanel = false }: any) {
  const { allChats } = useAppSelector((state: any) => state?.userChat);
  // const state = store.getState();
  // const allChats = state.userChat.allChats;
  const { isLoading, unreadChats } = useContext(ChatContext);
  // console.log("unreadChats : ", unreadChats);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const [selectedChatList, setSelectedChatList] = useState<String>("active");
  // const [showArchivedChats, setShowArchivedChats] = useState<boolean>(false);
  const [approvedChats, setApprovedChats] = useState<Array<any>>([]);
  const [pendingChats, setPendingChats] = useState<Array<any>>([]);
  const [archivedChats, setArchivedChats] = useState<Array<any>>([]);
  const [searchText, setSeachText] = useState<string>("");

  const extractApprovedChats = () => {
    const approved = allChats?.filter((chat: any) => {
      return (
        (chat?.status === "approved" && chat.block_status === "active") ||
        (["rejected", "pending"].includes(chat?.status) &&
          chat?.chat_name.split("-")[0] == loggedInUser?.id &&
          chat?.block_status !== "blocked") ||
        (chat.block_status === "blocked" &&
          !chat.block_by.includes(loggedInUser?.id))
      );
    });
    // console.log("approved", approved);
    setApprovedChats(approved);
  };

  const extractPendingChats = () => {
    const pendingChatsChats = allChats?.filter((chat: any) => {
      return (
        chat.status === "pending" &&
        chat?.chat_name.split("-")[0] != loggedInUser?.id
      );
    });
    // console.log("pendingChats", pendingChats);
    setPendingChats(pendingChatsChats);
  };

  // TODO: get blocked chats
  const extractBlockedChat = () => {
    const blockedChats = allChats?.filter((chat: any) => {
      return (
        (chat?.status === "rejected" &&
          chat?.chat_name.split("-")[1] == loggedInUser?.id) ||
        (chat.block_status === "blocked" &&
          chat.block_by.includes(loggedInUser?.id))
      );
    });
    // console.log("blockedChats", blockedChats);
    setArchivedChats(blockedChats);
  };

  const calculateTotalUnreadChatMsgCount = useCallback(() => {
    // Get common objects by id
    const commonObjects = unreadChats.filter((obj1: any) =>
      approvedChats.some((obj2: any) => obj1?.chatId == obj2.id)
    );
    let total = 0;
    commonObjects?.map((chat: any) => {
      total += chat?.count;
    });
    return total;
  }, [unreadChats]);

  useEffect(() => {
    // console.log("all chats updated to redux");
    extractApprovedChats();
    extractPendingChats();
    extractBlockedChat();
  }, [JSON.stringify(allChats)]);

  useEffect(() => {
    if (!searchText) {
      if (
        (selectedChatList === "request" && pendingChats?.length == 0) ||
        (selectedChatList === "archive" && archivedChats?.length == 0)
      ) {
        setSelectedChatList("active");
      }
    }
  }, [pendingChats, archivedChats]);

  useEffect(() => {
    if (searchText) {
      if (selectedChatList === "active") {
        filterApprovedChats();
      } else if (selectedChatList === "request") {
        filterPendingChats();
      } else {
        filterArchivedChats();
      }
    } else {
      extractApprovedChats();
      extractBlockedChat();
      extractPendingChats();
    }
  }, [searchText]);

  useEffect(() => {
    // console.log(selectedChatList);
    extractApprovedChats();
    extractBlockedChat();
    extractPendingChats();
  }, [selectedChatList]);

  const filterApprovedChats = () => {
    const filteredChats = allChats?.filter((chat: any) => {
      return (
        chat?.receiver?.name
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) &&
        (chat?.status === "approved" ||
          (chat.status === "pending" &&
            chat?.chat_name.split("-")[0] == loggedInUser?.id))
      );
    });
    // console.log("filterApprovedChats", filteredChats);
    setApprovedChats(filteredChats);
  };

  const filterPendingChats = () => {
    const filteredChats = allChats?.filter((chat: any) => {
      return (
        chat?.receiver?.name
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) &&
        chat.status === "pending" &&
        chat?.chat_name.split("-")[0] != loggedInUser?.id
      );
    });
    setPendingChats(filteredChats);
  };

  const filterArchivedChats = () => {
    const filteredChats = allChats?.filter((chat: any) => {
      return (
        chat?.receiver?.name
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) && chat.block_status === "blocked"
      );
    });
    // console.log("filterArchivedChats", filteredChats);
    setArchivedChats(filteredChats);
  };

  return (
    <>
      <div
        className={`w-full ${
          sidePanel ? "h-fit p-[15px]" : "h-[85vh] p-0"
        } bg-[var(--Color1E1E1E)] relative sm:rounded-2xl`}
      >
        {/* Search */}
        <div className="h-[8%]">
          <Input
            size="small"
            placeholder="Search"
            prefix={<BsSearch className="mx-2 font-bold" />}
            style={{ backgroundColor: "none !important" }} // Override background color here
            onChange={(e) => setSeachText(e.target.value)}
            className="!bg-white"
          />
        </div>

        {/* chat list select icons */}
        <div
          className={`flex items-center justify-between w-[50%] mx-auto ${
            sidePanel ? `pt-4 pb-2` : `pb-2 pt-3`
          } h-[10%]`}
        >
          <AllChatSelectorIcon
            activated={approvedChats?.length > 0 ? true : false}
            Icon={<BsChat />}
            tooltipTitle="Active Chats"
            selected={selectedChatList === "active"}
            handleClick={() => {
              setSelectedChatList("active");
            }}
            chatCount={calculateTotalUnreadChatMsgCount()}
          />

          <AllChatSelectorIcon
            activated={pendingChats?.length > 0 ? true : false}
            Icon={<BsChatQuote />}
            tooltipTitle="Chat Requests"
            selected={selectedChatList === "request"}
            handleClick={() => {
              setSelectedChatList("request");
            }}
            chatCount={pendingChats?.length}
          />

          <AllChatSelectorIcon
            activated={archivedChats?.length > 0 ? true : false}
            Icon={<BsArchive />}
            tooltipTitle="Archive Chats"
            selected={selectedChatList === "archive"}
            handleClick={() => {
              setSelectedChatList("archive");
            }}
            chatCount={archivedChats?.length}
          />
        </div>

        {/* chat list */}
        {/* <div className="h-[50%] overflow-y-scroll scrollbar-hide">
         */}
        <div className="h-[82%] overflow-y-scroll scrollbar-hide">
          {selectedChatList === "active" ? (
            isLoading ? (
              <>
                <p>Loading...</p>
              </>
            ) : (
              <ChatList
                chatList={approvedChats}
                setSelectedChat={setSelectedChat}
                setChatIsOpen={setChatIsOpen}
              />
            )
          ) : selectedChatList === "request" ? (
            <ChatList
              chatList={pendingChats}
              setSelectedChat={setSelectedChat}
              setChatIsOpen={setChatIsOpen}
            />
          ) : (
            <ChatList
              chatList={archivedChats}
              setSelectedChat={setSelectedChat}
              setChatIsOpen={setChatIsOpen}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default AllChats;
