import NoSearch from "pages/searchPage/shared/NoSearch";
import MovieCarouselCard from "pages/videoPage/shared/MovieCarouselCard";
import MovieAndSeriesCardSkeleton from "components/skeleton/MovieAndSeriesCardSkeleton";
import SubText from "components/shared/SubText";

function CreatorsHomeMovies({
  creatorData,
  slug,
  isLoading,
  setIsSelected,
  onClick,
}: any) {
  const movies = creatorData?.movies?.data || [];

  const handleSelectSeeAll = () => {
    setIsSelected(3);
    if (onClick) onClick();
  };
  return (
    <div>
      {!isLoading && (
        <>
          {movies?.length === 0 && movies ? (
            <NoSearch text="Movies" />
          ) : (
            <>
              <div className="flex justify-between mt-10 ml-3 max-sm:ml-0">
                <SubText text="Movies" className="font-bold" />
                {movies?.length > 6 && (
                  <p
                    onClick={handleSelectSeeAll}
                    className="font-[500] text-[15px] text-[#F50537] cursor-pointer mr-10 max-sm:mr-3"
                  >
                    See all
                  </p>
                )}
              </div>
              <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
                {movies?.slice(0, 6)?.map((movie: any, index: any) => (
                  <div key={index} className="mb-5 mr-4 max-sm:ml-[-15px]">
                    <MovieCarouselCard
                      movie={movie}
                      id={movie.id}
                      time={movie.runtime}
                      title={movie.title}
                      info={movie.title}
                      year={movie.release}
                      type={movie.genre}
                      img={movie.thumbnail}
                      slug={movie.slug}
                      // user={user}
                      isLoading={isLoading}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
      {isLoading && <MovieAndSeriesCardSkeleton />}
    </div>
  );
}

export default CreatorsHomeMovies;
