import { Form } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { HiArrowLeft } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  loginFailure,
  loginStart,
  loginSuccess,
  updateFavorites,
} from "redux/userRedux";
import AuthService from "services/auth/AuthService";
import LikeService from "services/music/LikeService";
import Logo from "Assets/Images/a_logo.png";
import LoadingSpin from "components/Loading spins/LoadingSpin";
import { RouteName } from "constant/routeNames";
import AntDInputField from "./editProfile/AntDInputField";

import CoverPoster from "Assets/Images/CoverPoster.jpg";

function Login() {
  let navigate = useNavigate();
  const location = useLocation();
  const receivedData = location.state;
  console.log(receivedData);

  const dispatch = useDispatch<any>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [searchParams] = useSearchParams();
  const email_verify_message = searchParams.get("email_verify_message");

  const [loadingSpin, setLoadingSpin] = useState<boolean>(false);
  const [emailVerfiedError, setEmailVerfiedError] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({
    email: [],
    password: [],
  });

  useEffect(() => {
    if (loggedInUser) {
      if (receivedData && receivedData?.from == "connectTv") {
        navigate(RouteName.ConnectTv);
      } else {
        navigate(RouteName.Home);
      }
    }
  }, [loggedInUser, navigate]);

  useEffect(() => {
    if (email_verify_message) {
      toast.info(email_verify_message);
    }
  }, [email_verify_message]);

  const submit = async (e: any) => {
    setLoadingSpin(true);
    e.preventDefault();
    removeErrors();
    dispatch(loginStart());
    const formData = form.getFieldsValue();
    await AuthService.login(formData)
      .then((response: any) => {
        setLoadingSpin(true);

        if (response?.status === 200) {
          // console.log(response?.data?.data?.user);
          dispatch(loginSuccess(response?.data?.data?.user));
          toast.success("Login successful");
          const loggedUser = response?.data?.data?.user;

          if (loggedUser) {
            // get user favorites
            LikeService.getFavoriteById(loggedUser?.id, loggedUser?.id)
              .then((res) => {
                // if (res?.status === 200) {
                // console.log(res);
                dispatch(updateFavorites(res.profile));
                // }
              })
              .catch((e: any) => {});

            console.log(receivedData);
            if (receivedData && receivedData?.from == "connectTv") {
              navigate(RouteName.ConnectTv);
              return;
            }

            if (response?.data?.data?.user?.old_user === 0) {
              navigate(RouteName.Home);
            } else {
              navigate(RouteName.UpdateOldUserPassword);
            }

            // navigate(RouteName.Home);
          } else {
            navigate(RouteName.Login);
          }
        } else {
          toast.error("There is an issue when attempting to log in");
          dispatch(loginFailure);
        }
        setLoadingSpin(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingSpin(false);
        if (error?.response && error?.response?.status === 422) {
          const errorData = error.response.data.errors;
          setErrors(errorData);
        } else if (error?.response?.status === 401) {
          const emailError = [
            "The provided credentials do not match our records. Please try again.",
          ];
          updatePasswordError(emailError);
        } else if (error?.response?.status === 403) {
          setEmailVerfiedError(true);
        } else {
          toast.error("An error occurred. Please try again.");
        }
        dispatch(loginFailure);
      });
  };

  const updatePasswordError = (error: any) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: error,
    }));
  };

  const removeErrors = () => {
    setErrors({
      email: [],
      password: [],
    });
    setEmailVerfiedError(false);
  };

  const handleVerifyEmail = async () => {
    const formData = form.getFieldsValue();
    if (formData.email === "") {
      toast.error("Please enter your email address");
      return;
    }
    setLoadingSpin(true);
    await AuthService.resendVerifyEmail(formData.email)
      .then((response) => {
        if (response.status) {
          setLoadingSpin(false);
          form.resetFields();
          setEmailVerfiedError(false);
          toast.success("We have sent you the verification email");
        }
      })
      .catch((error) => {
        setLoadingSpin(false);
        if (error?.response && error?.response?.status === 403) {
          toast.error("Your email is already verified");
        } else if (error?.response?.status === 404) {
          toast.error("Email not found");
        } else {
          toast.error("An error occurred. Please try again.");
        }
      });
  };

  // const seoImage = "https://ayozat.co.uk/logo192.png";
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  return (
    <>
      <Helmet>
        <title>AYOZAT - Login</title>
        <meta
          name="description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <link rel="canonical" content={`${FE_URL}/login`} />
        <meta property="og:title" content="AYOZAT - Login" />
        <meta
          property="og:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta property="og:url" content={`${FE_URL}/login`} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="webapp" />
        <meta name="twitter:title" content="AYOZAT - Login" />
        <meta
          name="twitter:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT - Login" />
        <meta name="twitter:url" content={`${FE_URL}/login`} />
      </Helmet>

      <div
        className="overflow-hidden max-sm:w-[100%] "
        // main back image
        style={{
          color: "#fff",
          backgroundImage: `url(${CoverPoster})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        {/* red dot top left */}
        <div className=" loginpage-back scrollbar-hide">
          <div
            className="absolute h-[800px] w-[800px] -left-[350px] -top-[350px] max-sm:hidden"
            style={{
              color: "#fff",
              backgroundImage: `url('https://i.stack.imgur.com/G7vn7.png')`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
          <div className="overflow-hidden login-page-form-container scrollbar-hide">
            <div
              className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer mb-4`}
            >
              <HiArrowLeft
                className="text-black"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            {/* ayosat logo part */}
            <Link to={RouteName.Home}>
              <div className="relative left-0 bg-white rounded-full w-[100px] h-[100px] flex items-center mb-10">
                <img src={Logo} alt="logo" className="w-16 h-16 mx-auto mb-2" />
              </div>
            </Link>

            {/* welcome */}
            <div className="text-left">
              <h1 className="mb-2 text-4xl font-bold white-text sm:text-4xl">
                Welcome!
              </h1>
              <p className="mb-4 text-sm tracking-wide gray-text">
                Already a user? Log in.
              </p>
            </div>

            {/* social logina */}
            {/* <div>
              <div className="flex ">
                <button className="flex items-center justify-center w-full py-2 mb-4 mr-1 font-bold rounded-full text-md white-text button-red facebook-button">
                  <FaFacebookF className="mr-1 text-xl " /> Facebook
                </button>
                <button className="flex items-center justify-center w-full py-2 mb-4 ml-1 font-bold rounded-full text-md white-text button-red apple-button">
                  <FaApple className="mr-1 text-3xl" />
                  Apple
                </button>
              </div>
              <button className="flex items-center justify-center w-full py-2 mb-4 font-bold border-2 border-white rounded-full text-md white-text ">
                <img
                  src="https://img.icons8.com/fluency/48/000000/google-logo.png"
                  alt="google"
                  className="w-8 h-8 mr-2"
                />
                Google
              </button>
              <div className="grid grid-cols-3 w-[80%] mx-auto mb-3">
                <hr className="mt-3" />
                <p className="text-sm gray-text">Or Log in with</p>
                <hr className="mt-3" />
              </div>
            </div> */}

            {/* main topic section */}

            <div className="relative">
              <Form form={form}>
                <AntDInputField
                  className=" !mb-2"
                  name="email"
                  type="email"
                  placeholder="Email"
                  size="large"
                  password={false}
                />
                {errors?.email ? (
                  <p className="red-text">{errors?.email}</p>
                ) : (
                  <></>
                )}
                <AntDInputField
                  className=" !mb-2"
                  name="password"
                  placeholder="Password"
                  type="password"
                  password={true}
                />
                {errors?.password ? (
                  <p className="red-text">{errors?.password}</p>
                ) : (
                  <></>
                )}
                <p className="mt-2 text-xs gray-text text-start">
                  <Link to={RouteName.ForgotPassword}>
                    <span className="cursor-pointer ">Forgot password?</span>
                  </Link>
                </p>

                {emailVerfiedError && (
                  <div className="w-full px-4 py-3 mt-3 text-left bg-red-400 rounded">
                    <h4 className="mb-1 text-sm font-bold">
                      Email Verification Required
                    </h4>
                    <p className="mb-2 text-sm">
                      Please check your inbox for the verification link, or
                      click below to receive a new one.
                    </p>
                    <button
                      className="text-xs underline"
                      onClick={handleVerifyEmail}
                    >
                      Get verfication email
                    </button>
                  </div>
                )}

                <button
                  className="w-full py-4 my-4 text-lg font-bold rounded-full white-text button-red"
                  type="submit"
                  onClick={submit}
                >
                  {loadingSpin ? (
                    <LoadingSpin fontSize={20} color={"white"} />
                  ) : (
                    `Log In`
                  )}
                </button>
              </Form>

              <p className="text-sm ">
                <div className="flex">
                  <div className="grid grid-cols-7 w-[100%] mx-auto mb-3">
                    <hr className="col-span-3 mt-4 opacity-50 " />
                    <p className="col-span-1 text-sm text-center gray-text  border-[gray] border rounded-lg py-2 ">
                      or
                    </p>
                    <hr className="col-span-3 mt-4 opacity-50" />
                  </div>
                </div>
                <Link to={RouteName.Register}>
                  <button className="w-full py-4 my-4 text-lg font-bold rounded-full white-text border border-[white]">
                    Register Now
                  </button>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
