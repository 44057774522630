import React, { useEffect, useState } from "react";
import ConnectTvService from "services/liveTv/ConnectTvService";
import LoadingPinSkeleton from "./LoadingPinSkeleton";
import "styles/connectTvLoading.css";

function ConnectTvPopUp() {
  const [pinValueArray, setPinValueArray] = useState<number[]>([]);
  const [pinData, setPinData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number>(0);

  const fetchPin = () => {
    setIsLoading(true);
    ConnectTvService.generatePin().then((res) => {
      setPinData(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchPin();
  }, []);

  useEffect(() => {
    if (!pinData) return;

    const expiresAt = new Date(pinData.expires_at).getTime();
    const now = new Date().getTime();
    const initialTimeLeft = Math.floor((expiresAt - now) / 1000);
    setTimeLeft(initialTimeLeft);

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalId);
          fetchPin();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [pinData]);

  const convertNumberToArray = (num: number): number[] => {
    return num
      ?.toString()
      .split("")
      .map((digit) => parseInt(digit, 10));
  };

  useEffect(() => {
    setPinValueArray(convertNumberToArray(pinData?.pin));
  }, [pinData]);

  return (
    <div className="py-5 text-center border-[1px] border-gray-700 rounded-xl">
      <h2 className="mb-5 text-2xl text-white">Connect to the TV</h2>

      <div className="mb-5 text-white">
        <p>To use the pin code, please follow these steps:</p>
        <ul className="mt-2 list-inside">
          <li>Go to the TV app.</li>
          <li>
            Select <strong>Login via Pin</strong>.
          </li>
          <li>Enter the pin code displayed below.</li>
          <li>
            Click <strong>Submit</strong> to complete the login.
          </li>
        </ul>
      </div>

      {isLoading ? (
        <LoadingPinSkeleton />
      ) : (
        <>
          <div className="flex justify-center w-full mx-auto">
            {pinValueArray &&
              pinValueArray.map((pinValue, index) => (
                <div key={index} className="connect-tv-pin">
                  {pinValue}
                </div>
              ))}
          </div>
          <div className="mt-3 text-center text-white">
            <p>Here is your verification code</p>
            <p>
              It will expire in <span className="text-red-600">{timeLeft}</span>{" "}
              second{timeLeft !== 1 && "s"}.
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default ConnectTvPopUp;
