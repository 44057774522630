import { RouteName } from "constant/routeNames";
import { Link } from "react-router-dom";

function ActorImage({ actor }: any) {
  // console.log(actor);
  return (
    <>
      <Link to={actor && RouteName.VideoCreatorHomeRoute.replace(":slug", `@${actor?.slug}`)}>
        <div
          className="relative w-32 rounded-lg h-44 bg-img white-text max-sm:w-[95%] flex items-end overflow-hidden"
          style={{
            background: `url(${actor?.artwork_url}) no-repeat center`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="flex items-end p-2 gradient-card-back">
            <p className="text-xs cursor-default">{actor?.name}</p>
          </div>
        </div>
      </Link>
    </>
  );
}

export default ActorImage;
