import SubText from "components/shared/SubText";
import SongList from "pages/musicPage/shared/SongList";
import React, { useState, useEffect } from "react";
import SongListHome from "./SongListHome";
import MostPlayedSongSkeleton from "components/skeleton/MostPlayedSongSkeleton";
import MusicTableTow from "pages/musicPage/shared/MusicTableTow";

function CreatorHomeTracksGrid({
  userPlaylists,
  setUserPlaylists,
  favorite,
  songs,
  removeFromPlaylist,
  setRemovedSongId,
  handleShowMore,
  user,
  playlistId,
}: any) {
  return (
    <>
      {songs && (
        <>
          <div className="flex justify-between w-[90%] items-center mt-10 ml-3 max-sm:ml-0 max-sm:w-full">
            <SubText text="Tracks" className="font-bold" />
            {songs?.length > 3 && (
              <p
                className="text-[#F50537] font-[500] text-[14px] my-5 cursor-pointer"
                onClick={handleShowMore}
              >
                Show more
              </p>
            )}
          </div>
          <div className="w-[90%] mt-5 ml-3 text-white max-sm:overflow-x-hidden max-sm:ml-0 max-sm:w-full">
            <div className="w-full text-white max-sm:overflow-x-hidden">
              <table className="w-full px-2">
                <thead className="text-left">
                  <tr className="cursor-default">
                    <th className="text-[12px] text-[#7B9DB4] w-[5%] pl-3 max-sm:pl-0">
                      #
                    </th>
                    <th className=" text-[12px] text-[#7B9DB4] w-[35%] max-sm:pl-5">
                      Track
                    </th>
                    <th className="text-[12px] text-[#7B9DB4] w-[25%] ">
                      Artist
                    </th>
                    <th className="text-[12px] text-[#7B9DB4] w-[25%] max-sm:ml-[10px] ">
                      Duration
                    </th>
                  </tr>
                </thead>
                <tr>
                  <td colSpan={5} className="pr-2 max-sm:pr-3">
                    <hr className="my-5 border-[#7B9DB4] pr-4" />
                  </td>
                </tr>
                <tbody className="my-5">
                  {songs?.slice(0, 3)?.map((data: any, i: number) => (
                    <MusicTableTow
                      id={i + 1}
                      data={data}
                      user={user}
                      key={i}
                      userPlaylists={userPlaylists}
                      setUserPlaylists={setUserPlaylists}
                      favorite={favorite}
                      removeFromPlaylist={removeFromPlaylist}
                      setRemovedSongId={setRemovedSongId}
                      playlistId={playlistId}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CreatorHomeTracksGrid;
