import QueueList from "./shared/QueueList";
import CreatorWatchPage from "./CreatorWatchPage";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import VideoService from "services/video/VideoService";
import { useDispatch, useSelector } from "react-redux";
import {
  removeLiveTVData,
  setVideoLoaded,
  setVideoStart,
  setVideoSuccess,
} from "redux/VideoPlayerRedux";
import { removeAllPlayer } from "redux/AudioPlayerRedux";
import { getLastWatchTime } from "utils/timeFunctions";
import { AssetTypes } from "constant/AssetTypes";
import { RouteName } from "constant/routeNames";
import { toast } from "react-toastify";
import VideoCreatorDetails from "./shared/VideoCreatorDetails";
import Footer from "components/Footer";
import ContentNavbar from "components/ContentNavbar";
import WelcomePageSidebar from "pages/welcome/WelcomePageSidebar";
import { contentNavData } from "constant/ContentNavBarData";
import CreatorService from "services/creators/CreatorService";
import Ripple from "components/Ripple";
import CreatorPlayer from "./shared/CreatorPlayer";
import React from "react";
import CreatorDeatilsOnPlayer from "./shared/CreatorDetailsOnPlayer";
import { Helmet } from "react-helmet-async";

function VideoCreatorPlaylist({ handleClick, isActive, setIsActive }: any) {
  const { id, slug, stream } = useParams();
  // const navigate = useNavigate();
  const [openGeoLoactionModel, setOpenGeoLoactionModel] = useState(false);

  const dispatch = useDispatch<any>();
  const [videoDetails, setVideoDetails] = useState<any>({});
  const [error, setError] = useState("");
  const { lastWatched } = useSelector((state: any) => state?.video);
  const [episodes, setEpisodes] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { currentVideo } = useSelector((state: any) => state?.video);
  const [innerHeight, setInnerHeight] = React.useState<number>(
    window.innerWidth / 2
  );
  const [title, setTitle] = useState<any>();
  const [thumbnail, setThumbnail] = useState<any>();

  const [episodeDetails, setEpisodeDetails] = useState<any>({});
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;
  // console.log("slug", slug);

  useEffect(() => {
    // setLoading(false);
    setIsLoading(false);
    dispatch(removeLiveTVData());
    dispatch(removeAllPlayer());

    const loadData = async () => {
      dispatch(setVideoStart());

      setTimeout(async () => {
        await CreatorService.getCreatorVideoDetailsByStreamKey(stream)
          .then(async (res) => {
            // console.log(res.creator_video);
            // if (res?.data?.allowed_to_watch === false) {
            //   setOpenGeoLoactionModel(true);
            // }
            // console.log("playlist video", res);
            setVideoDetails(res?.creator_video);
            setTitle(res?.creator_video?.title);
            setThumbnail(res?.creator_video?.creator_artist?.artwork_url);
            dispatch(setVideoSuccess(res?.creator_video));

            // console.log(res?.creator_video.creator.slug + "Current " + slug);

            //filtering playlist
            if (res?.creator_video?.creator_artist?.slug === slug) {
              setLoading(false);
              if (res?.creator_video.playlist_id !== null) {
                const filteredVideos =
                  res?.creator_video.creator_artist.creator_videos.filter(
                    (video: any) =>
                      video.playlist_id === res?.creator_video.playlist_id
                  );
                setEpisodes(filteredVideos);
              } else {
                setEpisodes(res?.all_videos);
              }
              setLoading(true);
            }

            dispatch(setVideoLoaded());
            setIsLoading(true);
          })
          .catch((err) => {
            toast.error("Content Not Found");
            setError("Content Not Found, Please try again");
            dispatch(setVideoLoaded());
          });
      }, 1000);
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream, slug]);

  // console.log(videoDetails.playlist_id);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // console.log(innerHeight);

  return (
    <>
      <Helmet>
        <title>
          {videoDetails?.title ? `${videoDetails?.title} - AYOZAT` : "AYOZAT"}
        </title>
        <meta name="title" content={videoDetails?.title} />
        <meta name="description" content={videoDetails?.description} />
        <link
          rel="canonical"
          href={`${FE_URL}/stream/${videoDetails?.creator?.slug}/${videoDetails?.stream_key}`}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={videoDetails?.title} />
        <meta name="twitter:description" content={videoDetails?.description} />
        <meta name="twitter:image" content={videoDetails?.thumbnail_url} />
        <meta
          name="twitter:url"
          content={`${FE_URL}/stream/${videoDetails?.creator?.slug}/${videoDetails?.stream_key}`}
        />

        <meta property="og:title" content={videoDetails?.title} />
        <meta property="og:description" content={videoDetails?.description} />
        <meta property="og:image" content={videoDetails?.thumbnail_url} />
        <meta
          property="og:url"
          content={`${FE_URL}/stream/${videoDetails?.creator?.slug}/${videoDetails?.stream_key}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* add code */}
      <ContentNavbar handleClick={handleClick} isActive={isActive} />
      <div className="mt-[-10px]">
        <WelcomePageSidebar
          isActive={isActive}
          navData={contentNavData}
          className=""
          setIsActive={setIsActive}
        />
      </div>
      <div
        className={
          isActive
            ? "md:pl-[100px] pl-[80px] duration-500 p-2 mt-2 ml-3 max-sm:pl-0 max-sm:ml-2 max-sm:mt-2"
            : "pl-0 duration-500 p-2 mt-2 ml-8 max-sm:pl-0 max-sm:ml-2  "
        }
      >
        {/* end code */}
        <div className="flex justify-between w-full max-sm:block max-md:block ">
          <div className="block mx-auto w-[90%]  max-sm:w-full mb-5 max-sm:ml-0 max-sm:block md:pl-[100px] ml-3  max-md:w-full  md:-ml-28 ">
            {isLoading ? (
              <div className="">
                <CreatorPlayer
                  videoDetails={videoDetails}
                  innerHeight={innerHeight}
                  setInnerHeight={setInnerHeight}
                />
              </div>
            ) : (
              <div
                className="flex items-center justify-center "
                style={{ height: `${(innerHeight * 4.2) / 5}px` }}
              >
                <Ripple />
              </div>
            )}
            {/* <CreatorWatchPage videoDetails={videoDetails} loading={loading} /> */}
            <div className="relative w-full max-sm:mt-5 max-md:w-full">
              <VideoCreatorDetails
                videoDetails={videoDetails}
                isLoading={isLoading}
              />
            </div>
          </div>
          <div className="w-[35%] max-sm:ml-0 flex-col flex  max-sm:w-full max-md:w-full max-sm:flex max-sm:gap-3  ">
            <CreatorDeatilsOnPlayer
              videoDetails={videoDetails}
              title={title}
              thumbnail={thumbnail}
              isLoading={loading}
            />
            <div
              className={`flex justify-center w-full mt-3 overflow-hidden max-sm:w-full scrollbar-hide`}
              style={{ height: `${(innerHeight * 4.2) / 5}px` }}
            >
              <div className="flex justify-start w-full !overflow-y-scroll custom-scrollbar overflow-x-hidden max-sm:w-full max-md:w-full max-lg:w-full ">
                {/* Queue List */}
                <QueueList
                  isLoading={loading}
                  episodes={episodes}
                  seasonId={id}
                  videoDetails={videoDetails}
                  slug={videoDetails?.creator_artist?.slug}
                  setVideoDetails={setVideoDetails}
                  setEpisodeDetails={setEpisodeDetails}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[100px] max-sm:mt-0 ">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default VideoCreatorPlaylist;
