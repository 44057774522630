import CreatorHomeTracksGrid from "./shared/CreatorHomeTracksGrid";
import CreatorHomeVideoGrid from "./shared/CreatorHomeVideoGrid";
import CreatorHomeAlbumsGrid from "./shared/CreatorHomeAlbumsGrid";
import CreatorHomeLiveTVGrid from "./shared/CreatorHomeLiveTVGrid";
import CreatorsMovies from "./shared/CreatorsHomeMovies";
import SubText from "components/shared/SubText";
import CreatorsSeries from "./shared/CreatorHomeSeries";
import CreatorHomeRadioGrid from "./shared/CreatorHomeRadioGrid";
import CreatorHomePodcastGrid from "./shared/CreatorHomePodcastGrid";
import CreatorHomePayperviewGrid from "./shared/CreatorHomePayperviewGrid";
import CreatorsHomeSeries from "./shared/CreatorHomeSeries";
import CreatorsHomeMovies from "./shared/CreatorsHomeMovies";

function HomePage({
  isLoading,
  creatorData,
  handleShowMore,
  handleCreatorVideoShowMore,
  isSelected,
  setIsSelected,
}: any) {
  const tvChannels = creatorData?.tv_channels;
  const albums = creatorData?.albums;
  const songs = creatorData?.music;
  const podcasts = creatorData?.podcasts;
  const radios = creatorData?.radios;
  const ppv = creatorData?.ppvEvents?.data;

  return (
    <>
      <div className="flex max-sm:w-full max-sm:block max-lg:block max-xl:block max-xl:w-full">
        {creatorData?.music?.length !== 0 ? (
          <div className="w-[50%] max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full ">
            <CreatorHomeTracksGrid
              songs={songs}
              handleShowMore={handleShowMore}
              // userPlaylists={userPlaylists}
              // setUserPlaylists={setUserPlaylists}
              // favorite={favoriteIds}
            />
          </div>
        ) : (
          <>
            {creatorData?.tv_channels?.length !== 0 && (
              <div className="w-full px-4 max-sm:w-full max-sm:px-0 max-md:block max-lg:block max-lg:w-full max-lg:px-0 max-lg:mt-3 max-xl:w-full max-xl:block max-xl:px-0 max-xl:mt-3 ">
                {creatorData?.tv_channels?.length !== 0 && (
                  <CreatorHomeLiveTVGrid
                    tvChannels={tvChannels}
                    isSelected={isSelected}
                    setIsSelected={setIsSelected}
                    musicLength={creatorData?.music?.length}
                  />
                )}
              </div>
            )}
          </>
        )}
        {creatorData?.tv_channels?.length !== 0 && (
          <div
            className={`px-4 w-[50%] max-sm:w-full max-sm:px-0  max-md:block max-lg:block max-lg:w-full max-lg:px-0 max-lg:mt-3 max-xl:w-full max-xl:block max-xl:px-0 max-xl:mt-3 ${
              creatorData?.music?.length === 0 ? "hidden" : "block"
            }`}
          >
            <CreatorHomeLiveTVGrid
              tvChannels={tvChannels}
              isSelected={isSelected}
              setIsSelected={setIsSelected}
            />
          </div>
        )}
      </div>

      {/* <div className="flex max-sm:w-full max-sm:block max-lg:block max-xl:block max-xl:w-full"> */}
      {creatorData?.albums?.length !== 0 && (
        <div className="max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full ">
          <CreatorHomeAlbumsGrid
            albums={albums}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
          />
        </div>
      )}
      {/* </div> */}
      {creatorData?.videos?.length !== 0 && (
        <div className="w-full max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full ">
          <CreatorHomeVideoGrid
            isLoading={isLoading}
            creatorData={creatorData}
            handleCreatorVideoShowMore={handleCreatorVideoShowMore}
          />
        </div>
      )}

      <div className="flex max-sm:w-full max-sm:block max-lg:block max-xl:block max-xl:w-full">
        <div className="w-[100%] max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full ">
          {creatorData?.movies?.data.length !== 0 && (
            <div className="mt-5">
              <CreatorsHomeMovies
                creatorData={creatorData}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
              />
            </div>
          )}

          {creatorData?.series?.data?.length !== 0 && (
            <div className="mt-5">
              <CreatorsHomeSeries
                creatorData={creatorData}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
              />
            </div>
          )}
        </div>
      </div>
      {/* podcast */}
      <div className="flex max-sm:w-full max-sm:block max-md:w-full max-lg:block max-xl:w-full">
        {creatorData?.podcasts?.length !== 0 ? (
          <div className="w-[50%] max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full ">
            <CreatorHomePodcastGrid
              podcasts={podcasts}
              isSelected={isSelected}
              setIsSelected={setIsSelected}
            />
          </div>
        ) : (
          <>
            {creatorData?.radios?.length !== 0 && (
              <div className="px-4 w-[50%] !mt-0 max-sm:w-full max-sm:px-0  max-md:w-full max-lg:block max-lg:w-full max-lg:px-0  max-xl:w-full max-xl:block max-xl:px-0 ">
                {creatorData?.radios?.length !== 0 && (
                  <CreatorHomeRadioGrid
                    radios={radios}
                    isSelected={isSelected}
                    setIsSelected={setIsSelected}
                  />
                )}
              </div>
            )}
          </>
        )}
        {creatorData?.radios?.length !== 0 && (
          <div
            className={`px-4 w-[50%] max-sm:w-full max-sm:px-0  max-md:w-full max-lg:block max-xl:w-full max-lg:px-0  max-xl:block max-xl:px-0  ${
              creatorData?.podcasts?.length === 0 ? "hidden" : "block"
            }`}
          >
            <CreatorHomeRadioGrid
              radios={radios}
              isSelected={isSelected}
              setIsSelected={setIsSelected}
            />
          </div>
        )}
      </div>

      {/* ppv */}
      {creatorData?.ppvEvents?.data?.length !== 0 && (
        <div className="max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full ">
          <CreatorHomePayperviewGrid
            ppvEvents={ppv}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
          />
        </div>
      )}
    </>
  );
}

export default HomePage;
