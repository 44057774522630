import React, { useState, useEffect } from "react";
import MusicTableTow from "./MusicTableTow";

function MusicTable({
  userPlaylists,
  setUserPlaylists,
  favorite,
  songs,
  removeFromPlaylist,
  setRemovedSongId,
  user,
  playlistId,
}: any) {
  const [seeAll, setSeeAll] = useState(false);

  const visibleSongs = seeAll ? songs : songs?.slice(0, 6);

  const handleShowMore = () => {
    setSeeAll(!seeAll);
  };
  return (
    <>
      {visibleSongs && (
        <div className="w-full text-white max-sm:overflow-x-hidden">
          <table className="w-full px-2">
            <thead className="text-left">
              <tr className="cursor-default">
                <th className="text-[12px] text-[#7B9DB4] w-[5%] pl-3 max-sm:pl-0">
                  #
                </th>
                <th className=" text-[12px] text-[#7B9DB4] w-[35%] max-sm:pl-5">
                  Track
                </th>
                <th className="text-[12px] text-[#7B9DB4] w-[25%] ">Artist</th>
                <th className="text-[12px] text-[#7B9DB4] w-[25%] max-sm:ml-[10px] ">
                  Duration
                </th>
              </tr>
            </thead>
            <tr>
              <td colSpan={5} className="pr-2 max-sm:pr-3">
                <hr className="my-5 border-[#7B9DB4] pr-4" />
              </td>
            </tr>
            <tbody className="my-5">
              {visibleSongs?.map((data: any, i: number) => (
                <MusicTableTow
                  id={i + 1}
                  data={data}
                  user={user}
                  key={i}
                  userPlaylists={userPlaylists}
                  setUserPlaylists={setUserPlaylists}
                  favorite={favorite}
                  removeFromPlaylist={removeFromPlaylist}
                  setRemovedSongId={setRemovedSongId}
                  playlistId={playlistId}
                />
              ))}
            </tbody>
          </table>

          {songs?.length > 6 && (
            <p
              className="text-[#F50537] font-[500] text-[14px] my-5 cursor-pointer"
              onClick={handleShowMore}
            >
              {seeAll ? `Show fewer` : `Show more`}
            </p>
          )}
          {/* <p
        className="text-[#F50537] font-[500] text-[14px] my-5 cursor-pointer"
        onClick={handleShowMore}
      >
        {seeAll ? `Show fewer` : `Shore more`}
      </p> */}
        </div>
      )}
    </>
  );
}

export default MusicTable;
