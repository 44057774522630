import UserPageNavButton from "components/shared/UserPageNavButton";
import React, { useEffect, useState } from "react";
import HomePage from "../HomePage";
import VideosPage from "../VideosPage";
import PlayListsPage from "../PlayListsPage";
import AboutPage from "../AboutPage";
import LiveTvPage from "../LiveTvPage";
import RadioPage from "../RadioPage";
import PodcastPage from "../PodcastPage";
import MusicPage from "../MusicPage";
import MoviesPage from "../MoviesPage";
import SearchPageSkeleton from "components/skeleton/SearchPageSkeleton";
import PayperviewPage from "../PayperviewPage";

function VideoCreatorButtonSection({
  creatorData,
  isLoading,
  setIsLoading,
}: any) {
  const [isSelected, setIsSelected] = useState<any>(1);
  const [isExpanded, setIsExpanded] = useState(false);

  const [components, setComponents] = useState<any>({
    id: 1,
    component: HomePage,
    name: "HOME",
    data: [
      ...(creatorData?.recent_videos || []),
      ...(creatorData?.movies?.data || []),
      ...(creatorData?.series?.data || []),
      ...(creatorData?.albums || []),
      ...(creatorData?.tv_channels || []),
    ],
  });

  const filterAndCapitalizeButtons = (creatorData: any) => {
    const filteredButtons = [
      {
        id: 1,
        component: HomePage,
        name: "Home",
        data: [
          ...(creatorData?.recent_videos || []),
          ...(creatorData?.movies?.data || []),
          ...(creatorData?.series?.data || []),
          ...(creatorData?.music || []),
          ...(creatorData?.albums || []),
          ...(creatorData?.tv_channels || []),
          ...(creatorData?.podcasts || []),
          ...(creatorData?.radios || []),
          ...(creatorData?.ppvEvents?.data || []),
        ],
      },
      {
        id: 2,
        component: LiveTvPage,
        name: "Live TV",
        data: creatorData?.tv_channels,
      },
      {
        id: 3,
        component: VideosPage,
        name: "Video",
        data: [
          ...(creatorData?.videos || []),
          ...(creatorData?.movies?.data || []),
          ...(creatorData?.series?.data || []),
          ...(creatorData?.playlists || []),
          ...(creatorData?.acted_movies || []),
          ...(creatorData?.acted_tv_shows || []),
          ...(creatorData?.produced_movies || []),
          ...(creatorData?.produced_tv_shows || []),
          ...(creatorData?.directed_movies || []),
          ...(creatorData?.directed_tv_shows || []),
        ],
      },
      {
        id: 5,
        component: MusicPage,
        name: "Music",
        data: [...(creatorData?.music || []), ...(creatorData?.albums || [])],
      },
      {
        id: 6,
        component: PodcastPage,
        name: "Podcast",
        data: creatorData?.podcasts,
      },
      {
        id: 7,
        component: RadioPage,
        name: "Radio",
        data: creatorData?.radios,
      },
      {
        id: 8,
        component: PayperviewPage,
        name: "PPV",
        data: creatorData?.ppvEvents?.data,
      },
      // {
      //   id: 9,
      //   component: PlayListsPage,
      //   name: "Playlists",
      //   data: creatorData?.playlists,
      // },
      {
        id: 10,
        component: AboutPage,
        name: "About",
        data: [
          // ...(creatorData?.general_info?.data || []),
          ...(creatorData?.videos || []),
          ...(creatorData?.movies?.data || []),
          ...(creatorData?.series?.data || []),
          ...(creatorData?.playlists || []),
        ],
      },
    ];

    const visibleButtons = filteredButtons.filter((button) => {
      if (button.data) {
        // Check if the data is an array and has elements
        return Array.isArray(button.data) && button.data.length > 0;
      } else {
        return true; // Always show HOME and About buttons
      }
    });

    return visibleButtons.map((button) => ({
      ...button,
      name: button.name,
    }));
  };

  // Usage
  const visibleButtons = filterAndCapitalizeButtons(creatorData);

  useEffect(() => {
    if (visibleButtons.length > 0) {
      setIsSelected(visibleButtons[0].id);
      setComponents({
        id: visibleButtons[0].id,
        component: visibleButtons[0].component,
        name: visibleButtons[0].name,
        data: visibleButtons[0].data,
      });
    }
  }, [creatorData]);

  const handleShowMore = () => {
    const musicButton = visibleButtons.find(
      (button) => button.name === "Music"
    );

    if (musicButton) {
      setIsSelected(musicButton.id);
      setComponents({
        id: musicButton.id,
        component: musicButton.component,
        name: musicButton.name,
        data: musicButton.data,
      });
    }
  };

  const handleCreatorVideoShowMore = () => {
    const videoButton = visibleButtons.find(
      (button) => button.name === "Video"
    );

    if (videoButton) {
      setIsSelected(videoButton.id);
      setComponents({
        id: videoButton.id,
        component: videoButton.component,
        name: videoButton.name,
        data: videoButton.data,
      });
    }
  };

  console.log("click", isExpanded);

  //logic to see all
  useEffect(() => {
    if (visibleButtons.length > 0) {
      const selectedButton = visibleButtons.find(
        (button) => button.id === isSelected
      );

      if (selectedButton) {
        setTimeout(() => {
          window.scrollTo({
            top: 100,
            behavior: "smooth",
          });
        }, 500);
        setComponents({
          id: selectedButton.id,
          component: selectedButton.component,
          name: selectedButton.name,
          data: selectedButton.data,
        });
      }
    }
  }, [isSelected]);

  // console.log("isSelected", isSelected);
  return (
    <>
      {!isLoading && (
        <div className="bg-[#171717] block rounded-3xl max-sm:mt-20 py-2 mt-10 overflow-hidden">
          {/* <HeroBoosterSection follow="257 Followers" /> */}
          <div className="flex items-center justify-start w-full ml-3 gap-1 my-5 max-sm:my-3 max-sm:grid max-sm:grid-cols-3 max-xl:grid max-xl:grid-cols-5 max-md:grid max-md:grid-cols-4 max-2xl:grid-cols-6 max-sm:mx-0 max-sm:gap-1">
            {visibleButtons.map((button: any, i: number) => (
              <div key={i} className="mt-5 ml-5 max-sm:mt-1 max-sm:ml-3">
                <UserPageNavButton
                  pages={button}
                  setIsSelected={setIsSelected}
                  isSelected={isSelected}
                  selected={button.id === isSelected ? true : false}
                  isLoading={isLoading}
                  setComponents={setComponents}
                />
              </div>
            ))}
          </div>

          <div
            id="section1"
            className="ml-5 text-white mb-[150px] pb-[100px] max-sm:ml-3"
          >
            {components && (
              <components.component
                creatorData={creatorData}
                isLoading={isLoading}
                handleShowMore={handleShowMore}
                handleCreatorVideoShowMore={handleCreatorVideoShowMore}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
              />
            )}
          </div>
        </div>
      )}
      {isLoading && <SearchPageSkeleton />}
    </>
  );
}

export default VideoCreatorButtonSection;
