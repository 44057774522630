import { ArtistCard } from "pages/musicPage/shared/ArtistCard";
import NoFavorite from "./shared/NoFavorite";
import MusicTable from "pages/musicPage/shared/MusicTable";
import OverviewSkeleton from "components/skeleton/OverviewSkeleton";
import FavMusicAndArtistSkeleton from "components/skeleton/FavMusicAndArtistSkeleton";
import { useEffect, useState } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

function UserMusicPage({
  user,
  favoriteData,
  favoriteMusicIds,
  userPlaylists,
  setUserPlaylists,
  isLoading,
}: any) {
  const [songs, setSongs] = useState<any>([]);
  // const [allArtist, setAllArtist] = useState<any>([]);
  const favorite = favoriteData?.loved.data || [];
  const favoriteArtist = favoriteData?.favorite_artists || [];

  useEffect(() => {
    setSongs(favorite);
    // setAllArtist(favoriteArtist);
  }, []);

  return (
    <>
      {!isLoading && (
        <div>
          {/* top section */}
          <div className="mb-8 w-[95%] ml-6 mt-8 max-sm:ml-0">
            <div className="">
              <h2 className="text-lg font-medium text-white lg:text-xl md:text-2xl">
                {/* Music assets by User */}
                Favourite Music
              </h2>
              {/* <UserArtistGrid /> */}
              {/* <UserAlbumsGrid audios={boosterAudio} /> */}

              {songs?.length === 0 && songs ? (
                <div>
                  <NoFavorite text="Songs" user={user} />
                </div>
              ) : (
                <div className="my-5 max-sm:w-full">
                  <MusicTable
                    songs={songs && songs}
                    userPlaylists={userPlaylists}
                    setUserPlaylists={setUserPlaylists}
                    favorite={favoriteMusicIds}
                    user={user}
                  />
                </div>
              )}

              {/* <UserTrackList
            songs={favorite}
            user={user}
            favorite={favoriteMusicIds}
            userPlaylists={userPlaylists}
            setUserPlaylists={setUserPlaylists}
          /> */}
            </div>
          </div>

          {/* top section */}
          {/* <div className="mb-8 w-[95%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
            <div>
              <h2 className="text-lg font-medium text-white lg:text-xl md:text-2xl">
                Favourite Artists
              </h2>

              {favoriteArtist?.length === 0 && favoriteArtist ? (
                <div>
                  <NoFavorite text="Artists" user={user} />
                </div>
              ) : (
                <div>
                  <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-0 max-sm:w-full max-sm:gap-3 ">
                    {favoriteArtist?.length > 0 &&
                      favoriteArtist?.map((artist: any, i: number) => (
                        <div key={i}>
                          <ArtistCard artist={artist} />
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div> */}
        </div>
      )}
      {isLoading && <FavMusicAndArtistSkeleton />}
    </>
  );
}

export default UserMusicPage;
