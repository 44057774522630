import { Badge, Tooltip } from "antd";
import SelectorIcon from "./SelectorIcon";

function AllChatSelectorIcon({
  activated,
  Icon,
  tooltipTitle,
  handleClick,
  selected,
  chatCount,
}: any) {
  const handleChatIcon = () => {
    if (handleClick && activated) {
      handleClick();
    }
  };
  return (
    <Tooltip title={tooltipTitle}>
      {chatCount ? (
        <Badge count={chatCount} overflowCount={10} size="default">
          <SelectorIcon
            Icon={Icon}
            activated={activated}
            selected={selected}
            handleChatIcon={handleChatIcon}
          />
        </Badge>
      ) : (
        <SelectorIcon
          Icon={Icon}
          activated={activated}
          selected={selected}
          handleChatIcon={handleChatIcon}
        />
      )}
    </Tooltip>
  );
}

export default AllChatSelectorIcon;
