import React from "react";
import NoSearch from "./NoSearch";
import { ArtistCard } from "pages/musicPage/shared/ArtistCard";

const SearchArtistSection = ({ data, user }: any) => {
  const artists = data?.artists?.data || [];

  // const hasCreator = artists.some((artist: any) =>
  //   artist?.type?.some((type: any) => type.name === "Creator")
  // );

  const isOnlyCreator =
    artists.length === 1 &&
    artists[0]?.type?.some((type: any) => type.name === "Creator");

  if (isOnlyCreator) {
    return null;
  }

  return (
    <div>
      {/* top section */}
      <div className="mb-8 w-[98%] ml-6 mt-8 max-sm:w-full max-sm:mx-0">
        <div>
          <h1 className="text-xl font-semibold text-white lg:text-xl md:text-xl">
            Artists
          </h1>
        </div>
      </div>

      {/* card section */}
      {artists?.length === 0 ? (
        <NoSearch text="Artists" />
      ) : (
        <div>
          <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
            {artists.map((artist: any, i: any) => {
              const isCreator = artist?.type?.some(
                (t: any) => t.name === "Creator"
              );
              if (isCreator) {
                return null;
              }

              return (
                <div key={i} className="mx-auto">
                  <ArtistCard artist={artist} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchArtistSection;
