import React, { useEffect, useState } from "react";
import LogoStream from "Assets/AyozatStreamwhite.png";
import { useNavigate } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import ConnectTvService from "services/liveTv/ConnectTvService";
import LoadingPinSkeleton from "components/ConnectTv/TvPopUp/LoadingPinSkeleton";
import { useSelector } from "react-redux";
import "styles/connectTvLoading.css";

function ConnectTv() {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  useEffect(() => {
    if (!loggedInUser) {
      navigate(RouteName.Login, { state: { from: "connectTv" } });
    }
  }, [loggedInUser]);

  const navigate = useNavigate();

  const handleClickLogo = () => {
    navigate(RouteName.Home);
  };

  const [pinValueArray, setPinValueArray] = useState<number[]>([]);
  const [pinData, setPinData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState<number>(0);

  const fetchPin = () => {
    setIsLoading(true);
    ConnectTvService.generatePin().then((res) => {
      setPinData(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (loggedInUser) {
      console.log("first");
      setTimeout(() => {
        fetchPin();
      }, 1500);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (!pinData) return;

    const expiresAt = new Date(pinData.expires_at).getTime();
    const now = new Date().getTime();
    const initialTimeLeft = Math.floor((expiresAt - now) / 1000);
    setTimeLeft(initialTimeLeft);

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalId);
          fetchPin();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [pinData]);

  const convertNumberToArray = (num: number): number[] => {
    return num
      ?.toString()
      .split("")
      .map((digit) => parseInt(digit, 10));
  };

  useEffect(() => {
    setPinValueArray(convertNumberToArray(pinData?.pin));
  }, [pinData]);
  return (
    <>
      {loggedInUser && (
        <div className="min-h-screen p-5">
          <div className="flex items-center justify-center w-full">
            <img
              src={LogoStream}
              alt="logo"
              className="cursor-pointer w-[230px] "
              onClick={handleClickLogo}
            />
          </div>
          <div className="mt-5 text-center text-white max-md:mt-10">
            <h2 className="mb-5 text-2xl text-red-600">Connect to the TV</h2>

            <div className="mb-5 text-white">
              <p>To use the pin code, please follow these steps:</p>
              <ul className="mt-2 text-gray-400 list-inside">
                <li>Go to the TV app.</li>
                <li>
                  Select <strong>Login via Pin</strong>.
                </li>
                <li>Enter the pin code displayed below.</li>
                <li>
                  Click <strong>Submit</strong> to complete the login.
                </li>
              </ul>
            </div>
          </div>

          {isLoading ? (
            <LoadingPinSkeleton />
          ) : (
            <>
              {pinValueArray && pinValueArray?.length > 0 && (
                <>
                  <div className="flex justify-center w-full mx-auto">
                    {pinValueArray &&
                      pinValueArray.map((pinValue, index) => (
                        <div key={index} className="connect-tv-pin">
                          {pinValue}
                        </div>
                      ))}
                  </div>
                  <div className="mt-3 text-center text-white">
                    <p>Here is your verification code</p>
                    <p>
                      It will expire in{" "}
                      <span className="text-red-600">{timeLeft}</span> second
                      {timeLeft !== 1 && "s"}.
                    </p>

                    <p
                      className="w-[100px] text-center py-2 rounded-full white-text bg-red-600 mx-auto mt-5 cursor-pointer hover:scale-[1.05] ease-in-out duration-150"
                      onClick={handleClickLogo}
                    >
                      Home
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ConnectTv;
