import React, { useEffect, useRef, useState } from "react";

function NavIconLayout({ icon, content, img, className }: any) {
  //main menu dropdown
  const [isActive, setIsActive] = useState(false);
  const popRef = useRef<HTMLDivElement | null>(null);

  const dropDown = (e: any) => {
    setIsActive((current) => !current);
  };

  const handleClickOutsideMainMenu = (event: MouseEvent) => {
    if (popRef.current && !popRef.current?.contains(event.target as Node)) {
      setIsActive(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideMainMenu);
    return () => {
      document.removeEventListener("click", handleClickOutsideMainMenu);
    };
  }, []);
  return (
    <>
      <div className={`relative ${className ? className : ""}`} ref={popRef}>
        <div onClick={dropDown}>
          {icon && (
            <>
              {React.cloneElement(icon, {
                className: `ml-5 text-2xl cursor-pointer white-text`,
              })}
            </>
          )}

          {img && (
            <>
              <img
                className="w-8 h-8 ml-5 -mt-1 rounded-full cursor-pointer"
                src={img}
                alt=""
              />
            </>
          )}
        </div>
        {isActive && (
          <div className={`${icon && "max-sm:hidden z-50"}`}>{content}</div>
        )}
      </div>
      {icon && isActive && (
        <div className={`sm:hidden h-10 p-2 w-full left-0 absolute z-50`}>
          {/* {isActive && <div className="flex justify-center">{content}</div>} */}
          {/* <div className="flex justify-center bg-red-600">{icon}</div> */}
          <div className="!flex !justify-center">{content}</div>
        </div>
      )}
    </>
  );
}

export default NavIconLayout;
