const CreatorHeaderSkeleton = () => {
  return (
    <div className="w-[40%] ml-[-30px]">
      <div className="flex flex-col mt-24">
        <div className="flex flex-row gap-2 gap-x-[30px]"></div>
        <div className="lg:w-[250px] w-[250px] h-[20px] bg-[#1a1919]  animate-pulse mt-2"></div>
        <div className="lg:w-[350px] w-[350px] h-[30px] bg-[#1a1919]  animate-pulse mt-2"></div>
        {/* <div className="lg:w-[400px] h-[60px] bg-[#1a1919] animate-pulse mt-2"></div> */}
      </div>

      <div className="flex w-[65%] mt-5 flex-row justify-between">
        {/* <div className="w-[160px] h-[50px] rounded-full bg-[#1a1919]"></div> */}
        {/* <div className="w-[160px] h-[50px] mr-[10px] rounded-full bg-[#1a1919]"></div> */}
        <div className="w-[160px] h-[50px] mr-[10px] rounded-full bg-[#1a1919]"></div>
      </div>
    </div>
  );
};

export default CreatorHeaderSkeleton;
