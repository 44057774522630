import React from "react";

function SelectorIcon({ activated, selected, Icon, handleChatIcon }: any) {
  return (
    <div
      className={`border-[1px] border-gray-700 rounded-lg p-3 ${
        activated
          ? `text-white border-white cursor-pointer hover:text-[#F30543] hover:border-[#F30543]`
          : `text-gray-600`
      } ${selected ? `!text-[#F30543] !border-[#F30543]` : ``}`}
      onClick={handleChatIcon}
    >
      {Icon}
    </div>
  );
}

export default SelectorIcon;
