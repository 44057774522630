import React from "react";
import CreatorVideoCard from "./CreatorVideoCard";
import CreatorVideoSkeleton from "components/skeleton/CreatorVideoSkeleton";
import NoSearch from "pages/searchPage/shared/NoSearch";

function VideoCardGrid({ creatorData, slug, isLoading }: any) {
  const videos = creatorData?.videos || [];
  return (
    <div>
      {!isLoading && (
        <>
          {videos.length === 0 && videos ? (
            <NoSearch text="Videos" />
          ) : (
            // <></>
            <>
              <div className="mt-4 mb-4 w-[95%] grid grid-cols-4 gap-5 max-sm:grid-cols-1 max-md:grid-cols-2 max-lg:grid-cols-2 max-xl:grid-cols-4 max-2xl:grid-cols-4 max-[1700px]:grid-cols-4 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
                {videos?.map((video: any, index: any) => (
                  <div key={index} className=" mr-4 max-sm:ml-[-15px]">
                    <CreatorVideoCard
                      video={video}
                      slug={creatorData?.general_info.slug}
                      // season={episode?.season}
                      // id={episode?.id}
                      isLoading={isLoading}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
      {isLoading && <CreatorVideoSkeleton />}
    </div>
  );
}

export default VideoCardGrid;
