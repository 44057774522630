import NoSearch from "pages/searchPage/shared/NoSearch";
import MovieCarouselCard from "pages/videoPage/shared/MovieCarouselCard";
import MovieAndSeriesCardSkeleton from "components/skeleton/MovieAndSeriesCardSkeleton";
import SubText from "components/shared/SubText";

function CreatorsSeries({
  creatorData,
  slug,
  isLoading,
  setIsSelected,
  onClick,
}: any) {
  const series = creatorData?.series?.data || [];

  const handleSelectSeeAll = () => {
    setIsSelected(3);
    if (onClick) onClick();
  };

  return (
    <div>
      {!isLoading && (
        <>
          {series?.length === 0 && series ? (
            <NoSearch text="Series" />
          ) : (
            <>
              <div className="flex justify-between mt-10 ml-3">
                <SubText text="Series" className="font-bold" />
              </div>
              <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
                {series
                  ?.slice(0, series.length)
                  ?.map((movie: any, index: any) => (
                    <div key={index} className="mb-5 mr-4">
                      <MovieCarouselCard
                        id={movie?.id}
                        // time={card.runtime}
                        title={movie?.title}
                        // info={card.description}
                        // year={card.release}
                        // type={card.video_type}
                        img={movie?.thumbnail}
                        seasons={movie?.no_of_seasons}
                        slug={movie?.slug}
                        tv={true}
                        //  favoriteTvSeries={favorite}
                        tvSeries={movie}
                        first_episode={movie?.first_episode}
                      />
                    </div>
                  ))}
              </div>
            </>
          )}
        </>
      )}
      {isLoading && <MovieAndSeriesCardSkeleton />}
    </div>
  );
}

export default CreatorsSeries;
