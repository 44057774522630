import { Badge } from "antd";
import React, { useContext, useEffect, useState } from "react";
import img from "../../../Assets/Images/ProfileAvatar.png";
import { IUnreadChat } from "hooks/useUserChatWebSocket";
import { ChatContext } from "context/ChatContext";

const AllChatAvatar = ({ chatData, popUp = true }: any) => {
  // console.log(popUp);
  const { unreadChats, messages } = useContext(ChatContext);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  useEffect(() => {
    const chat: IUnreadChat = unreadChats.find(
      (chat: IUnreadChat) => chat.chatId == chatData?.id
    );
    setUnreadCount(chat ? chat.count : 0);
  }, [messages]);
  return (
    <Badge count={unreadCount} size="default">
      <img
        src={
          !chatData?.receiver?.artwork_url ||
          chatData?.receiver?.artwork_url ==
            "https://dapi.ayozat.co.uk/common/default/user.png"
            ? img
            : chatData?.receiver?.artwork_url
        }
        alt="profile_pic"
        className={`rounded-full ${
          popUp === true ? "w-12 h-11 " : "w-14 h-14"
        }`}
      />
    </Badge>
  );
};

export default AllChatAvatar;
