// import QRCode from "qrcode";
import { SetStateAction, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { useSelector } from "react-redux";

const QRPopUp = () => {
  const currentUser = useSelector((state: any) => state?.user?.currentUser);

  const [inputValue, setInputValue] = useState(currentUser?.access_token);

  return (
    <div className="p-10 text-center border-[1px] border-gray-700 rounded-xl">
      <h2 className="my-5 text-white">Scan Me Connect TV</h2>

      <div className="flex justify-center w-full mx-auto">
        <QRCodeCanvas value={inputValue} size={256} />
      </div>
    </div>
  );
};

export default QRPopUp;
