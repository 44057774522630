import React from "react";
import { IoMdChatbubbles } from "react-icons/io";

function NoConversationSelectedComponent() {
  return (
    <>
      <div className="flex items-center justify-center h-[85vh]">
        <IoMdChatbubbles className="text-gray-400 text-9xl" />
        <p className="text-lg text-gray-400">No Conversation Selected</p>
      </div>
    </>
  );
}

export default NoConversationSelectedComponent;
