import MovieAndSeriesCardSkeleton from "components/skeleton/MovieAndSeriesCardSkeleton";
import NoSearch from "pages/searchPage/shared/NoSearch";
import MovieCarouselCard from "pages/videoPage/shared/MovieCarouselCard";
import React from "react";

function Movies({ creatorData, slug, isLoading }: any) {
  const movies = creatorData?.acted_movies || [];
  const Prmovies = creatorData?.produced_movies || [];
  const Drmovies = creatorData?.directed_movies || [];
  const actedAll = [...movies, ...Prmovies, ...Drmovies];
  console.log("Movies",actedAll);

  return (
    <div>
      {!isLoading && (
        <>
          {actedAll?.length === 0 && actedAll ? (
            <NoSearch text="Movies" />
          ) : (
            <>
              <div className="mt-5 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
                {actedAll?.map((movie: any, index: any) => (
                  <div key={index} className="mb-5 mr-4">
                    <MovieCarouselCard
                      movie={movie}
                      id={movie.id}
                      time={movie.runtime}
                      title={movie.title}
                      info={movie.title}
                      year={movie.release}
                      type={movie.genre}
                      img={
                        movie?.seasons ? movie.thumbnail : movie.thumbnail_url
                      }
                      slug={movie.slug}
                      // user={user}
                      isLoading={isLoading}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
      {isLoading && <MovieAndSeriesCardSkeleton />}
    </div>
  );
}

export default Movies;
