import { useState } from "react";
import { useDispatch } from "react-redux";
import { addMusicToPlayer } from "redux/AudioPlayerRedux";
import { formatDuration } from "utils/timeFunctions";
import { RouteName } from "constant/routeNames";
import { Link } from "react-router-dom";
import CreatePlaylistIcon from "./../audioPlayer/AudioPlayer3/CreatePlaylistIcon/CreatePlaylistIcon";
import AddSongToQueue from "./AddSongToQueue";
import AddSongToFav from "./AddSongToFav";
import TableRowImage from "./TableRowImage";
import { Tooltip as ReactTooltip } from "react-tooltip";

const SongList = ({ value, index, userPlaylists, setUserPlaylists }: any) => {
  const dispatch = useDispatch<any>();
  // const [addFav, setAddFav] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);

  const handleMusicPlayer = () => {
    dispatch(addMusicToPlayer(value));
  };

  return (
    <>
      <tr
        className="h-20 text-white duration-150 ease-in-out border-b border-gray-800 rounded-md hover:bg-slate-800 hover:shadow-xl hover:text-slate-300"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <td>
          <p className="text-[#626A66] text-base mr-2">{index}</p>
        </td>
        <td className={`w-2/12 max-sm:w-3/12`}>
          <TableRowImage
            executionFunc={handleMusicPlayer}
            imgSrc={value?.artwork_url}
            imgAlt={"song"}
            imgHeight={"50px"}
            imgWidth={"50px"}
            hover={hover}
          />
        </td>
        <td className="max-lg:w-[full] w-[30%]">
          <Link to={RouteName.MusicTrackRoute.replace(":id", value.id)}>
            <p
              className="text-[#FFFFFF] font-[600] text-[13px] hover:text-gray-600"
              data-tooltip-id={
                value?.title?.length > 28
                  ? `music-most-played-title-${value?.title}`
                  : ""
              }
            >
              {/* {value.title} */}
              {value.title.length > 15
                ? `${value.title.slice(0, 15)}...`
                : value.title}
            </p>
            <div className="z-50 text-sm ">
              <ReactTooltip
                id={`music-most-played-title-${value?.title}`}
                place="top"
                content={value?.title}
                style={{ width: "180px" }}
              />
            </div>
          </Link>
          <Link
            to={RouteName.VideoCreatorHomeRoute.replace(
              ":slug",
              `@${value?.artists[0]?.slug}`
            )}
          >
            <p className="text-[#7B9DB4] font-[400] text-[8px] hover:text-red-600 ease-in-out duration-200">
              {value?.artists[0].name.length > 15
                ? `${value?.artists[0].name.slice(0, 15)}...`
                : value?.artists[0].name}
            </p>
          </Link>
        </td>
        <td className="text-[#FFFFFF] font-[400] text-[13px] max-sm:hidden max-md:hidden">
          {value.duration ? formatDuration(value.duration) : "00:00"}
        </td>
        <td className="items-center w-2/12">
          <div className=" items-center justify-end gap-6 pr-3 text-xl text-[#7B9DB4]  flex ">
            <AddSongToFav song={value} />

            {/* <AddSongToQueue song={value} /> */}
            <AddSongToQueue song={value} toolTiId={`new-released-card`} />

            {/* {user && ( */}
            <CreatePlaylistIcon
              userPlaylists={userPlaylists}
              setUserPlaylists={setUserPlaylists}
              mediaId={value.id}
            />
            {/* )} */}
          </div>
        </td>
      </tr>
    </>
  );
};

export default SongList;
