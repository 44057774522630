export enum RouteName {
  //basic routes
  Home = "/",
  Welcome = "/welcome",
  EditProfile = "/profile/settings",
  BecomeACreator = "/become-creator/*",
  Booster = "/booster/*",
  UserProfile = "/profile/*",
  Register = "/register",
  Login = "/login",
  UpdateOldUserPassword = "/user-update-password",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
  pageNotFound = "/page-not-found",
  Video = "/video/*",
  LiveTV = "/live-tv/*",
  Music = "/music/*",
  Radio = "/radio/*",
  Podcast = "/podcast/*",
  Blog = "/blogs/*",
  Search = "/search/:keyword",
  Apps = "/apps",
  TermsAndConditions = "/term-and-condition",
  PrivacyPolicy = "/privacy-policy",
  LegalInformation = "/legal-information",
  AboutUs = "/about-us",
  CookiesAndPersonalData = "/cookies-policy",
  DMCA = "/dmca",
  PayPerView = "/ppv/*",
  Playlists = "/playlist",
  StarPage = "/star/:slug",
  VideoCreator = "/*",
  transection = "/transactions",
  UserChat = "/messages/*",
  NewProfile = "/new-profile/*",
  ConnectTv = "/connect-tv",

  //video page routes//=========================================
  VideoDiscoverByGenreId = "discover/:id",
  VideoDiscover = "discover",
  VideoHistory = "history",
  VideoWatchlist = "watchlist",
  VideoFavourite = "favourite",
  VideoPlayerPage = ":slug",
  TVSeriesVideoPage = "/tv-series/:slug",
  SeeAllMovies = "all-movies",
  SeeAllSeries = "all-series",
  TopCategories = "topcategories",
  WatchMovies = "/watch/:slug",
  WatchTVSeries = "/watch/:slug/:stream",
  VideoDetail = "/info/:slug",

  //video page routes from origin
  VideoDiscoverRoute = "/video/discover",
  VideoHistoryRoute = "/video/history",
  VideoWatchlistRoute = "/video/watchlist",
  VideoFavouriteRoute = "/video/favourite",
  SeeAllMoviesRoute = "/video/all-movies",
  SeeAllSeriesRoute = "/video/all-series",
  SeeAllTopCategoriesRoute = "/video/topcategories",
  VideoDetailRoute = "/video/info/:slug",
  //=========================================
  //live-tv page routes//=========================================
  LiveTVDiscover = "discover",
  LiveTVHistory = "history",
  LiveTVChannel = "channel",
  LiveTVWatchlist = "watchlist",
  LiveTVFavourite = "favourite",
  LiveTVPlayer = "player",
  LiveTVPlayerLinkWithSlug = "/live-tv/:slug",
  LiveTVPlayerWithSlug = ":slug",
  SeeAllChannels = "category/:slug",

  //live-tv page from origin
  LiveTVDiscoverRoute = "/live-tv/discover",
  LiveTVHistoryRoute = "/live-tv/history",
  LiveTVWatchlistRoute = "/live-tv/watchlist",
  LiveTVChannelRoute = "/live-tv/channels",
  LiveTVPlayerRoute = "/live-tv/player",
  SeeAllChannelsRoute = "/live-tv/category/:slug",
  //=========================================
  //live-tv page routes=========================================
  MusicDiscover = "discover",
  MusicHistory = "history",
  MusicWatchlist = "watchlist",
  MusicCollection = "collection",
  MusicFavourite = "favourite",
  MusicAlbum = "album/:id",
  MusicPlaylist = "playlist/:id",
  MusicCategory = "category/:slug",
  MusicArtist = "artist/:slug",
  SeeAllArtist = "all-artist",
  SeeAllGenre = "all-genre",
  SeeAllNewRelease = "new-release",
  SeeAllAlbums = "all-albums",
  SeeAllDiscoverPlaylists = "/all-playlists",
  MusicTrack = "track/:id",

  //music page from origin
  MusicPlaylistPage = "/music/playlist/:id",
  MusicArtistPage = "/music/artist/:slug",
  MusicAlbumPage = "/music/album/:id",
  MusicCategoryPage = "/music/category/:slug",
  MusicDiscoverRoute = "/music/discover",
  MusicHistoryRoute = "/music/history",
  MusicWatchlistRoute = "/music/watchlist",
  MusicChannelRoute = "/music/channels",
  MusicColletionRoute = "/music/collection",
  SeeAllArtistRoute = "/music/all-artist",
  SeeAllGenreRoute = "/music/all-genre",
  SeeAllNewReleaseRoute = "/music/new-release",
  SeeAllAlbumsRoute = "/music/all-albums",
  SeeAllDiscoverPlaylistsRoute = "/music/all-playlists",
  MusicTrackRoute = "/music/track/:id",
  MusicLibrary = "/music/library",
  //=========================================
  //radio page routes=========================================
  RadioDiscover = "discover",
  RadioHistory = "history",
  RadioFavourite = "favourite",
  RadioChannelsByCategory = "channels/:slug",
  SeeAllRadioCategory = "all-category",
  SeeAllMightLike = "all-channels",
  RadioDescription = "/:slug",

  //radio page from origin
  RadioDiscoverRoute = "/radio/discover",
  RadioHistoryRoute = "/radio/history",
  RadioFavouriteRoute = "/radio/favourite",
  SeeAllRadioCategoryRoute = "/radio/all-category",
  SeeAllMightLikeRoute = "/radio/all-channels",
  RadioDescriptionRoute = "/radio/:slug",
  //=========================================
  //podcast page routes=========================================
  PodcastDiscoverByGenreId = "discover/:slug",
  PodcastDiscover = "discover",
  PodcastHistory = "history",
  PodcastFavourite = "favourite",
  PodcastOpenEpisode = ":id",
  PodcastMusicPlayer = "player",
  SeeAllPodcastCategory = "all-category",
  SeeAllNewPodcast = "all-podcast",

  //podcast page from origin
  PodcastDiscoverRoute = "/podcast/discover",
  PodcastHistoryRoute = "/podcast/history",
  PodcastFavouriteRoute = "/podcast/favourite",
  PodcastOpenEpisodeRoute = "/podcast/:id",
  SeeAllPodcastCategoryRoute = "/podcast/all-category",
  SeeAllNewPodcastRoute = "/podcast/all-podcast",

  //=========================================
  //Payperview page routes=======================================
  PayperviewDiscoverByGenreId = "discover/:slug",
  payperviewDiscover = "discover",
  PayperviewDetails = "details/:slug",
  PayperviewSuccesPage = "success",
  PaidSubscriptionsPage = "purchased",
  upcomingSeeAllPage = "allupcomingevent",
  purchasedSeeAllPage = "allpurchasedevent",
  pastSeeAllPage = "allpastevent",
  streamingSeeAllPage = "allstreamingevent",
  // payperviewReplayPlayerPage = "replay/:slug",

  //payperview page route from origin
  payperviewDiscoveRoute = "/ppv/discover/:slug",
  PayperviewDetailsRoute = "/ppv/details/:slug",
  PayperviewSuccesRoute = "/ppv/success",
  SeeAllPayperviewCategories = "all-category",
  PaidSubscriptionsRoute = "/ppv/purchased",
  upcomingSeeAllRoute = "/ppv/allupcomingevent",
  purchasedSeeAllRoute = "/ppv/allpurchasedevent",
  pastSeeAllRoute = "/ppv/allpastevent",
  streamingSeeAllRoute = "/ppv/allstreamingevent",

  //SEO page route//=========================================
  MusicSEOLandingPage = "/music-landing",
  LiveTVSEOLandingPage = "/live-tv-landing",
  VideoSEOLandingPage = "/video-landing",

  PPvLandingPage = "/ppv/influencer",

  //Booster page form origin
  BoosterVideoPage = "/boostervideo",
  BoosterOverviewPage = "/boostervideo",

  // user profile page from origin
  UserProfileHomeRoute = "/:id",
  UserFollowersRoute = "/:username/followers",
  UserFollowingsRoute = "/:username/followings",
  // user profiles by slug
  UserProfileHomeUserNameRoute = "/:username",
  UserFollowersUserNameRoute = "/:username/followers",
  UserFollowingsUserNameRoute = "/:username/followings",

  BecomeACreatorHome = "/:id",
  //creator routes
  VideoCreatorHome = ":slug",
  videoCreatorPlaylist = "playlist",
  CreatorPlaylist = "/stream/:slug/:stream",
  VideoCreatorPlayerPage = ":slug",
  SeeAllCreators = "creators",
  SeeAllCreatorsVideos = "all-videos",

  //creator routes from origin
  VideoCreatorHomeRoute = "/:slug",
  videoCreatorPlaylistRoute = "/playlist",
  CreatorPlaylistRoute = "/stream/:slug/:stream",
  SeeAllCreatorsRoute = "/creators",
  SeeAllCreatorsVideosRoute = "/all-videos",
  VideoCreatorPlayer = "/watch/:slug/:stream",

  PayPerViewPlayer = "/watch/event/:slug",
  PayPerViewCheckoutPath = "/checkout",
  PayPerViewReplayPlayer = "/replay/:slug/:stream",

  //Blogs page routes=========================================
  DiscoverBlogCategory = "discover/:slug",
  BlogDetails = ":slug",
  SeeAllBlogCategory = "categories",
  SeeAllBlogs = "all-blogs",

  //Blogs page from origin
  BlogRoute = "/blogs",
  BlogDiscoverRoute = "/blogs/discover/:slug",
  BlogDetailsRoute = "/blogs/:slug",
  SeeAllBlogCategoryRoute = "/blogs/categories",
  SeeAllBlogsRoute = "/blogs/all-blogs",

  // user profile page from origin
  NewUserProfileHomeRoute = "/:id",
  NewUserFollowersRoute = "/:username/followers",
  NewUserFollowingsRoute = "/:username/followings",
  // user profiles by slug
  NewUserProfileHomeUserNameRoute = "/:username",
  NewUserFollowersUserNameRoute = "/:username/followers",
  NewUserFollowingsUserNameRoute = "/:username/followings",

  // user chat from origin
  UserChatUsernameRoute = "/:username",
}
