import React from "react";
import CreatorMusic from "./shared/CreatorMusic";
import MusicTable from "pages/musicPage/shared/MusicTable";
import CreatorHomeAlbumsGrid from "./shared/CreatorHomeAlbumsGrid";

const MusicPage = ({ creatorData }: any) => {
  const songs = creatorData?.music;

  return (
    <div>
      {/* <CreatorMusic creatorData={creatorData} /> */}
      <div className="my-5">
        <MusicTable
          songs={songs}
          // userPlaylists={userPlaylists}
          // setUserPlaylists={setUserPlaylists}
          // favorite={favoriteIds}
        />
      </div>
      <CreatorHomeAlbumsGrid albums={creatorData?.albums} />
    </div>
  );
};

export default MusicPage;
